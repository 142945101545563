
.b-r_5{
    border-radius: 5px !important;
}
.bdr{
   border :  1px solid purple !important;
}
.over-scroll{
    overflow-y: scroll;
}
.text-align-center{
    text-align: center;
}
.text-align-end{
    text-align: end !important;
}
#onBoardFooter{
    background-color: #FFFFFF;
    box-shadow      : 0px -1px 12px 1px rgba(36, 50, 66, 0.08);
    padding         : 16px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    height          : 76px;
    position        : fixed;
    bottom          : 0px;
    left            : 0px;
    z-index         : 1000;
    width           : 30vw;
}
.bg-FFFFFF{
    background-color: #FFFFFF !important ;
}
.bg-FCFDFD{
    background-color: #FCFDFD !important ;
}
.h-50px{
    height: 50px !important;
}
.cardForm{
    max-width: 460px;
    width: calc(100% - 16px);
    padding: 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    justify-content: center;
    box-shadow: $shadow-auth;
    background-color: #fff;
    z-index: 100;
    gap: 32px;
}

.LoginCardLayout{
    display         : flex;
    align-items     : center;
    justify-content : center;
    flex-direction  : column;
    height          : calc(100vh - 64px);
    background-color: #fff;
    position        : relative;
}
.LoginCardLayoutSignup{
    display         : flex;
    align-items     : center;
    justify-content : center;
    flex-direction  : column;
    background-color: #fff;
    position        : relative;
}
.login-gradient{
    position: absolute;
    object-fit: contain;
    // height: 100%;
    // width: 30%;
    bottom: 0;
    left: 0;
    // background-color: red;
}

.old-walletly{
    display        : flex;
    flex-direction : row;
    align-items    : center;
    padding        : 0px 16px;
    gap            : 8px;
    height         : 52px;
    border         : 1px solid #D6DAE9;
    border-radius  : 6px;
    text-decoration: none !important;
    transition     : all 0.2s ease;
}

.old-walletly:hover{
    background-color: color(neutral0);
}

.auth-form{
display       : flex;
flex-direction: column;
align-items   : flex-start;
padding       : 0px;
gap           : 16px;
width         : 100%;

    /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

}


.top-header-auth{
  position      : absolute;
  width         : 100%;
  top           : 0;
  left          : 0;
  z-index       : 100;
  display       : flex;
  flex-direction: row;
  padding       : 16px 24px;
  align-items   : center;
}

#auth{
    
        display        : flex;
        width          : 100%;
        justify-content: space-between;
        
    .main_sub_1{
        width          : 50%;
        display        : flex;
        height         : 100vh;
        justify-content: center;
        padding-top    : 64px;
        align-items    : center;

    }
    .main_sub_11{
        width          : 40%;
        // display        : flex;
        height         : 100vh;
        // justify-content: center;
        // padding-top    : 64px;
        // align-items    : center;

    }
    .main_sub_1_child2{
        width          : 460px;
        display        : flex;
        padding        : 32px;
        flex-direction : column;
        justify-content: center;
        background     : #FFFFFF;
        // box-shadow     : 0px 12px 48px rgba(26, 33, 52, 0.11);
        // border-radius  : 24px;
    }
    .main_sub_1_child22{
        // width          : 460px;
        display        : flex;
        padding        : 32px;
        flex-direction : column;
        justify-content: center;
        background     : #FFFFFF;
        // box-shadow     : 0px 12px 48px rgba(26, 33, 52, 0.11);
        // border-radius  : 24px;
    }

    .main_sub_2_child{
        height  : 80%;
        position: relative;
       
    }
    .phoneOne{
        height  : 80%;
        position: absolute;
        right   : 10%;
        top     : 10%

    }
    .phoneTwo{
        height  : 80%;
        position: absolute;
        right   : 10%;
        top     : 20%;
    }
    
    .main_sub_1_child2 form{
        width: 100%;
    }
    
    .main_sub_2{
        width           : 50%;
        height          : 100vh;
        background-color: #FCFDFD;
        }
    .headSignin{
        margin-bottom: 25px;
    }
    .walletly{
        margin-top: 15px;
    }

}



#mainOnBo, #WMB{
    height : 100vh;
    display: flex;

    
    .topRightIcons{
        right          : 14px;
        top            : 12px;
        position       : absolute;
        width          : fit-content;
        justify-content: center;
        align-items    : center;
      }

      .topLeftIcons{
        left    : 16px;
        top     : 8px;
        position: absolute;
        width   : fit-content;
      }

    .totalHeight{
        height: calc(100vh - 64px);
    }

    .mainOB_C1{
        height         : 100%;
        display        : flex;
        justify-content: space-evenly;
    }

    .mainOB_C2{
        background     : #FCFDFD;
        justify-content: flex-start;
        margin-top     : 4vh;
        position       : relative;

    }

    .mainOB_C1_child2{
        padding   : 10px 20px;
        width     : 50%;
        margin-top: 4.8vh;
    }



    .singleLoyaltyType{
        padding: 10px 12px;
    }

    .servicesOuterBox{
        box-shadow      : -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        width           : 100%;
        margin-top      : 16px;
        background-color: white;
        border-radius   : 4px;
        z-index         : 1000;
    }

    .selectDropdown{
        border-radius   : 4px;
        color           : rgb(9, 30, 66);
        background-color: rgb(255, 255, 255);
        border          : 1px solid rgb(226, 232, 240);
        font-size       : 14px;
        padding         : 13px;
        width           : 100%;
    }

    .singleLoyaltyType:hover{
        background: #E8EFF7;
    }

    .activeCheckout{
        background: #E8EFF7;
    }

    .activeCheckout:hover{
        background: #E8EFF7;
    }

    .expandable{
        transition: all .3s ease-in-out;
        height    : 0;
        overflow  : hidden;
    }

    .expand{
        height    : 350px;
        max-height: 300px;
    }


    .specialp{
        color        : #84889B;
        font-weight  : 500;
        font-size    : 16px;
        margin-bottom: 15px;
    }

   
    
    .ParentOfDesgn{
        margin-top : 6.5vh;
        display    : flex;
        margin-left: 39px;
        width      : 100%;
        height     : 129px;
    }

    .ChildOneOfDesgn{
        background-color: #F5F7FC;
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;
        align-items     : center;
        border-radius   : 80px;
        width           : 22px;
        height          : 129px;
    }

    .ChildTwoOfDesgn{
        display        : flex;
        flex-direction : column;
        justify-content: space-between;
        width          : 100%;
        height         : 129px;
        text-align     : center;
    }

    .specialRound{
        width        : 16px;
        height       : 16px;
        background   : #B2B7CA;
        border-radius: 22px;
        margin-top   : 2px;
        transition   : all ease-in-out 0.4s;
        margin-bottom: 2px;
        border-radius: 50%;

    }
    .simpleRound{
        width        : 10px;
        height       : 10px;
        background   : #D6DAE9;
        border-radius: 50%;
        border-radius: 14px;
        margin-bottom: 3px;
        margin-top   : 3px;
    }
    .livePara{
        font-weight: 500;
        font-size  : 14px;
        color      : #4B4E5B;
        transition : all ease-in-out 0.4s;
        margin     : 0;
    }
    .unlivePara{
        color      : #B2B7CA;
        font-weight: 500;
        font-size  : 12px;
        margin     : 0;
    }
    .imgField{
        display        : flex;
        flex-direction : column;
        align-items    : center;
        border         : 1px solid #D6DAE9;
        border-radius  : 6px;
        height         : 108px;
        justify-content: center;
        
    }

    .imgField> h2 {
        font-weight: 500;
        font-size  : 14px;
        color      : #4B4E5B;
        margin-top : 3px;
    }

    .imgField> p {
        font-weight: 500;
        font-size  : 10px;
        color      : #9CA1B4;
    }

   

    .congratsSub{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    text-align: center;
    }


    .congratsSub h1{
        color      : #2E3039;
        width      : 504px;
        height     : 175px;
        font-weight: 700;
        font-size  : 40px;
        line-height: 60px;
    }

    .congratsSub button {
        background     : #2960EC;
        border-radius  : 6px;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center;
        padding        : 12px 24px;
        color          : #F2F6FF;
        border         : none;
        width          : 118px;
        height         : 44px;
        margin-top     : 38px;
        font-weight    : 500;
        font-size      : 14px;
        margin-top     : 38px;
    }
    
    .congratsSub p{
        color      : #141519;
        font-weight: 400;
        font-size  : 14px;
        line-height: 20px;
        margin-top : 23px;
        cursor     : pointer;
    }

    .dropImageCircle{
        width: 100%;
        height: 152px;
        margin: auto;
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: background-color ease .7s;
        &:hover{
            &.image{
                background-color: rgba(0,0,0,.6);
                border-radius: 4px;
            }
            .imageUpload{
                border-radius: 4px;
                opacity      : 1;
            }
        }
    }

    .removeIcon{
        top    : 8px;
        right  : 8px;
        z-index: 1000;
        position: fixed;
    }

    .imageUpload{
        transition: opacity ease .7s;
    }

    .imageUpload.remove{
        opacity: 0;
    }

    .insideImage{
        width     : 100%;
        height    : 148px;
        position  : absolute;
        z-index   : -1;
        top       : 0;
        object-fit: cover;
        left      : 0;
    }

    .box1{
        justify-content: center;
        width: 35%;
        border-right: 1px solid #F0F0F0;
    }

    .box2{
        width: 65%;
        text-align: center;
    }

    .caption{
        color: #000;
    }

    .circleRow{
        flex: 0 0 10.333333%;
        max-width: 10.333333%;
        padding-bottom: 7%;
    }

    .colorCircle{
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .selectedColor{
        transform: scale(1.4);
        transition: ease 0.3s;
    }

    

    @media (min-width: 1600px) {

        .belowButtons{
            width: 533px;
        }

    }


        .iphone-x {
            position       : relative;
            z-index        : 1;
            border-radius  : 50px;
            height         : 694px;
            width          : 342px;
            box-shadow     : rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }

          .iphone-x *, .iphone-x *::before, .iphone-x *::after {
            box-sizing: border-box;
            display: block;
          }

          .iphone-x .side {
            background: #1c1c1c;
            border-radius: 54px;
            box-shadow: inset 0 0 2px 2px dimgrey, inset 0 0 0 6px #e2e3e9, 0px -1px 13px 9px rgba(150,150,150,0.8) inset;
            height: 694px;
            padding: 15px 22px;
            width: 342px;
            z-index: 1;
          }
          .iphone-x .screen {
            background:#fff;
            background-position: center center;
            background-size: cover;
            border-radius: 33px;
            position: relative;
            height: 652px;
            width: 300px;
            margin-left: -1px;
            margin-top: 6px;
            z-index:198;
            overflow:hidden;
          }
          video {
            height: 700px;
            width: 350px;
            margin-top: -30px;
            margin-left:-10px;
          }
          .iphone-x .line::after, .iphone-x .line::before {
            content: "";
            position: absolute;
            border: solid rgba(68, 68, 68, 0.25);
            border-width: 0 6px;
            height: 5px;
            left: 0;
            width: 100%;
            z-index: 9;
          }
          .iphone-x .line::after {
            top: 68px;
          }
          .iphone-x .line::before {
            bottom: 68px;
          }
          .iphone-x .header {
            background: #1c1c1c;
            border-bottom-left-radius: 13px;
            border-bottom-right-radius: 13px;
            height: 24px;
            left: 50%;
            margin-left: -82px;
            position: absolute;
            top: 15px;
            width: 164px;
            z-index: 199;
          }
          .iphone-x .sensor-1::after, .iphone-x .sensor-1::before {
            content: "";
            position: absolute;
          }
          .iphone-x .sensor-1::after {
            background: #222;
            border-radius: 50%;
            height: 11px;
            width: 11px;
            left: 1%;
            margin-left: 10px;
            top: 3px;
          }
          .iphone-x .sensor-1::before {
            background: #222;
            border-radius: 50%;
            height: 11px;
            width: 11px;
            left: 10%;
            margin-left: 20px;
            top: 3px;
          }
          .iphone-x .sensor-2::after, .iphone-x .sensor-2::before {
            content: "";
            position: absolute;
          }
          .iphone-x .sensor-2::before {
            background: #333;
            border-radius: 2.5px;
            height: 5px;
            width: 40px;
            left: 50%;
            margin-left: -20px;
            top: 7px;
          }
          .iphone-x .sensor-3::after, .iphone-x .sensor-3::before {
            content: "";
            position: absolute;
          }
          .iphone-x .sensor-3::before {
            background: #444;
            border-radius: 50%;
            height: 11px;
            width: 11px;
            left: 50%;
            margin-left: 35px;
            top: 3px;
            box-shadow:0px 0px 5px 2px navy inset
          }
          .iphone-x .sensor-3::after {
            background: #222;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            left: 65%;
            margin-left: 33px;
          }
          .iphone-x .volume-button {
            background: #c8cacb;
            height: 26px;
            left: -2px;
            position: absolute;
            top: 92px;
            width: 3px;
          }
          .iphone-x .volume-button::after, .iphone-x .volume-button::before {
            content: "";
            position: absolute;
            background: #c8cacb;
            height: 50px;
            left: 0;
            width: 3px;
          }
          .iphone-x .volume-button::after {
            top: 48px;
          }
          .iphone-x .volume-button::before {
            top: 112px;
          }
          .iphone-x .power-button {
            background: #c8cacb;
            height: 80px;
            right: -2px;
            position: absolute;
            top: 160px;
            width: 3px;
          }

          .locationBlock{
            position: absolute;
            left    : 0px;
            top     : 330px;
            width   : 100%;
            padding : 16px;
          }

          .outlkMain{
            filter: drop-shadow(-5px 14px 50px rgba(0, 0, 0, 0.25));
        }
        
        .outlook-backGroundImage{
            // border: 0.5 solid green ;
            position: absolute;
            top     : 40px;
            left    : 0px;
            width   : 100%;
        }
        .outlook-backGroundImage  img{
            width: 100%;
        }
        
        .outlook-Logo{
            position     : absolute;
            top          : 150px;
            left         : 14px;
            border-radius: 50%;
        }

        .logoBorder{
            outline      : 5px solid white;
        }
        
        .outlook-businessName{
            position: absolute;
            top     : 195px;
            left    : 100px;
        
            h4{
                display: inline-block;
                width: 188px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .outlook-businessCategory{
            position: absolute;
            top     : 218px;
            left    : 100px;
        
            p{
                display: inline-block;
                width: 188px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .outlook-businessCategory p{
            color      : #84889B;
            font-weight: 400;
            font-size  : 10px;
        }
        
        .outlook-fourIcons{
            position       : absolute;
            display        : flex !important;
            width          : 100% ;
            left           : 0px;
            top            : 250px;
            height         : 65px;
            display        : flex;
            justify-content: space-evenly;
        
        }
        
        .outlook-fourIcons-child{
            width          : 20%;
            height         : 100%;
            background     : #F5F7FC;
            border-radius  : 5px;
            display        : flex !important;
            justify-content: center;
            align-items    : center;
        }
        
        .outlook-hoursRatingCostMain{
            position       : absolute;
            width          : 100% ;
            left           : 0px;
            top            : 335px;
            display        : flex !important;
            justify-content: space-evenly;
        }
        
        .outlook-hours{
            width       : 27%;
        }
        .outlook-hours h4{
            color      : #3DCC87;
            font-weight: 500;
            font-size  : 18px;
            line-height: 24px;
        }
        
        .outlook-rating{
            width       : 27%;
            border-left : 1px solid #C5C9DB;
            padding-left: 10px;
        
            
        }
        
        .outlook-rating h4{
            font-weight: 500;
            font-size  : 18px;
            line-height: 24px;
            align-items: center;
            color      : #6A93FC;
        }
        .outlook-cost{
            width       : 27%;
            border-left : 1px solid #C5C9DB;
            padding-left: 10px;
        
            
        }
        
        .outlook-cost h4{
            font-weight: 500;
            font-size  : 18px;
            line-height: 24px;
            align-items: center;
            color      : #686C7C;
        
        }
        
        .outlook-hours p ,.outlook-rating p , .outlook-cost p{
            color      : #84889B;
            font-weight: 400;
            font-size  : 12px;
            line-height: 16px;
        
        }
        
        .outlook-LocationHeading h4{
            font-weight: 500;
            font-size  : 16px;
        }
        
        .outlook-TimezoneLabel h3{
            font-weight: 500;
            font-size  : 10px;
            line-height: 16px;
        }
        
        .outlook-CurrencyLabel h3{
            font-weight: 500;
            font-size  : 10px;
            line-height: 16px;
        }
        .outlook-addressLabel h3{
            font-weight: 500;
            font-size  : 10px;
            line-height: 16px;
        
        
        }
        .fieldBorder{
            height       : 40px;
            background   : rgba(255, 255, 255, 0.01);
            border       : 1px solid #D6DAE9;
            border-radius: 6px;
            padding      : 12px;
        }
        
        .outlook-AddressField{
            width      : 100%;
            display    : flex;
  

            span{
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                align-items: center;
            }

        }
        
        .outlook-AddressField p{
            padding      : 8px 16px;
            font-weight  : 400;
            font-size    : 11px;
            margin-bottom: 0px;
        }
        
        .outlook-AddressField-another{
        width          : 281px;
        display        : flex;
        justify-content: space-evenly;
        }
        
        .outlook-country{
            width      : 130px;
            display    : flex;
            align-items: center;
        }
        .outlook-Timezone{
            width      : 130px;
            display    : flex;
            align-items: center;
        }
        .outlook-Currency{
            width      : 130px;
            display    : flex;
            align-items: center;
        }
        
        .outlook-country p ,.outlook-Currency p , .outlook-Timezone p{
            padding      : 8px 16px;
            font-weight  : 400;
            font-size    : 11px;
            margin-bottom: 0px;
            }
        .outlook-city{
            width: 130px;
            display: flex;
            align-items: center;
        }
        .outlook-city p{
            padding      : 8px 16px;
            font-weight  : 400;
            font-size    : 11px;
            margin-bottom: 0px;
            
            }
        
            @media only screen and (max-height: 806px) {
                .mainOB_C2 {
                  height: 110vh !important;
                }
              }
         
       
}
.selected{
    border: 1px solid #2960EC;
}
.speHeader{
    position: fixed;
    top     : 0px;
    background-color: #FFFFFF !important;
    }
.speRight{
    position: sticky;
    top     : 0px;
} 
.speAddBtn{
    // outline: 1px solid #F2F6FF !important;
    // background-color: #F2F6FF !important;
}

#mainSU_CU{
        justify-content: space-around;

   

    .ParentOfDesgn{
        margin-top : 156px;
        display    : flex;
        // margin-left: 39px;
        width      : 100%;
        height     : 129px;
    }
    .SpeLeft{
        position: sticky;
        height: 100vh;
        top: 0px;
    }
    
    
    

    .ChildOneOfDesgn{
        background-color: #F5F7FC;
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;
        align-items     : center;
        border-radius   : 80px;
        width           : 22px;
        height          : 129px;
    }

    .ChildTwoOfDesgn{
        display        : flex;
        flex-direction : column;
        justify-content: space-between;
        width          : 100%;
        height         : 129px;
        text-align     : center;
    }

    .specialRound{
        width        : 16px;
        height       : 16px;
        background   : #B2B7CA;
        border-radius: 22px;
        margin-top   : 2px;
        margin-bottom: 2px;

    }
    .simpleRound{
        width        : 10px;
        height       : 10px;
        background   : #D6DAE9;
        border-radius: 14px;
        transform    : matrix(-1, 0, 0, 1, 0, 0);
        margin-bottom: 3px;
        margin-top   : 3px;
    }
    .livePara{
        font-weight: 500;
        font-size  : 14px;
        color      : #4B4E5B;
        margin     : 0;
    }
    .unlivePara{
        color      : #B2B7CA;
        font-weight: 500;
        font-size  : 12px;
        margin     : 0;
    }
    .radioParent{
        padding      : 16px 125px 16px 15px;
        padding: 16px;
        gap          : 15px;
        width        : 396px;
        border       : 1px solid #D6DAE9;
        border-radius: 6px;
        margin-top   : 20px;
        display      : flex;
        justify-content: space-between;
        align-items: center;
    }
    .radioParentp{
        //  padding      : 16px 125px 16px 15px;
        padding        : 16px;
        gap            : 15px;
        width          : 396px;
        height         : 84px;
        border         : 1px solid #D6DAE9;
        border-radius  : 6px;
        margin-top     : 20px;
        display        : flex;
        justify-content: space-between;
        align-items    : center;
    }
   
    .selected{
        border: 1px solid #2960EC;
    }
    .standAlone h3{
        color      : #4B4E5B;

    }
    .standAlone p{
        color      : #9CA1B4;

    }
    .hedingbelowPara{
        font-weight: 500;
        font-size  : 16px;
        line-height: 24px;
        color      : #84889B;
    }
}



#ConnectedAccounts{
    .viewdetail{
        right: 0px;
        top: 14px;
    }
    .connecedMain h5{
        font-weight: 500;
        font-size  : 14px;
        line-height: 20px;
        margin-bottom     : 0;
        color      : #141519;
    }
    .connecedMain p{
        font-weight: 400;
        font-size  : 12px;
        line-height: 16px;
        color      : #686C7C;
        margin-bottom     : 0;
    
    }
}


#EarningWayOnboard{
    .singleEarningRange{
        background   : #FCFDFD;
        padding      : 14px 32px;
        border       : 0.5px solid #D6DAE9;
        box-shadow   : 0px 0px 6px rgba(128, 128, 128, 0.1);
        border-radius: 4px;
        margin-top   : 16px;
    }

    .borders{
        // border: 2px solid purple;

        // height: 60vh;
        // overflow-y: scroll;
    }
}

.borderSpecial{
    border-bottom: 0.5px solid #D6DAE9;
}
.cursor-pointer{
    cursor: pointer;
}
#Addrewards{
    .RewardsDiv h5{
        font-weight: 500;
        font-size  : 15px;
        line-height: 20px;
        color      : #141519;
    
    }
    .spaecial-neg-padding div{
        padding-left: 0px;
        padding-right: 0px;
        
    }
    .RewardsDiv p{
        font-weight   : 400;
        font-size     : 13px;
        line-height   : 20px;
        letter-spacing: 0.1px;
        color         : #686C7C;
    }
    .scrolly{
        overflow-y: scroll;
    }

}

.justify-content-flex-end{
    justify-content: flex-end;
}


#WaysToRedeem{
    // height    : 60vh;
    // overflow-y: scroll;
    .rewardsPara{
        font-style : normal;
        font-weight: 400;
        font-size  : 12px;
        line-height: 16px;
        color      : #84889B;
    }
    .scrolly{
        // height    : 70vh;
        overflow-y: scroll;
    }
    

}

.expandBox{
	max-height: 0px; /* approximate max height */
    transition: ease-in-out all 0.4s;
    overflow-y: hidden;
}

.expand{
    max-height: 700px !important;
    overflow-y: auto !important;

}




#Auth{

    .Footer{
        width           : 100%;
        background-color: color(primary0);
        padding         : 24px 20%;
        position        : sticky;
        bottom          : 0px;
    }

    .boxShadow{
        box-shadow: 0px 12px 48px rgba(26, 33, 52, 0.11);
        border-radius: 24px;
        // z-index: 100000;
    }

}